<template>
  <v-dialog
      :value="showSendNotificationForm"
      @input="(v) => v || HIDE_SEND_NOTIFICATION_FORM()"
      max-width="600px"
      scrollable
  >
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{  "ارسال اشعار جديد"
          }}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
              v-if="formLoading"
              color="primary"
              indeterminate
              rounded
              height="6"
          ></v-progress-linear>


          <div v-else>

            <v-text-field
                v-model="form.title"
                label="عنوان الاشعار"
                outlined
            ></v-text-field>


            <v-text-field
                v-model="form.description"
                label="نص الاشعار"
                outlined
            ></v-text-field>



          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            :loading="formLoading"
            color="secondary"
            elevation="1"
            dark
            @click="send_notification(form)"
        >ارسال</v-btn
        >
        <v-btn @click="HIDE_SEND_NOTIFICATION_FORM();">
          إلغاء
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapMutations , mapActions } from "vuex";

export default {
  name: "send-notification-form",

  data: function() {
    return {
      form: {},
      errors: {},
      formLoading: false
    };
  },
  created(){
  },
  computed: {
    ...mapState("userAds", {
      showSendNotificationForm: state => state.showSendNotificationForm,
      formRecord: state => {
        return {
          id: state.formRecord.id,
          title: state.formRecord.title,
          description: state.formRecord.description,
        };
      }
    })
  },

  watch: {
    formRecord(form) {
      this.form = form;
    }
  },

  methods: {
    ...mapMutations("userAds", ["HIDE_SEND_NOTIFICATION_FORM"]),
    ...mapActions("userAds", ["fetchRecords"]),
    send_notification(form) {
      this.formLoading = true;
      // form.section_id = this.item.id;
      // // console.log(form.section_id);
      // console.log(form);

      this.$store
          .dispatch("userAds/send_notification", { form })
          .then(response => {

            this.$bus.$emit("showSnackbar", {
              text: "تمت ارسال الاشعار بنجاح",
              color: "success"
            });
            this.HIDE_SEND_NOTIFICATION_FORM();
          })
          .catch(error => {
            if (error.response.status === 422) {
              this.errors = error.response.data.errors;
            }
            this.$bus.$emit("showSnackbar", {
              text: "خطا اثناء ارسال الاشعار",
              color: "error"
            });
            this.HIDE_SEND_NOTIFICATION_FORM();
          })
          .finally(() => {
            this.formLoading = false;
            this.fetchRecords()
          });
    },

  }
};
</script>
