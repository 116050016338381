<template>
  <div class="home page">

    <h2 class="accent--text text-center">

      <v-icon color="primary" class="ml-3" large>mdi-monitor-dashboard</v-icon>
      ادارة اعلانات المقدمة
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />

    <!-- CREATE / UPDATE FROM -->
    <record-form />


    <!-- NOTIFICATION FROM -->
    <send-notification-form />


    <!-- UPDATE / NEW RECORD -->
    <filters-box @fetch="fetchRecords({ page: 1 })" />

    <v-chip right class="mb-2" label color="secondary">

      <v-icon>mdi-account-circle-outline</v-icon> عدد الاعلانات {{ total_ads }}
    </v-chip>

    <v-toolbar flat class="d-flex justify-end">
    <!-- MULTI-DELETE BUTTON -->
    <v-btn color="error" @click="deleteSelectedAds">حذف المحدد</v-btn>



    </v-toolbar>

    <!-- TABLE -->
    <v-data-table
        item-key="id"
        class="elevation-1"
        :loading="table.loading"
        :headers="table.headers"
        :items="records"
        loading-text="Downloading Data"
        no-data-text="No Data"
        disable-filtering
        disable-pagination
        disable-sort
        hide-default-footer
    >


<!--      <template v-slot:[`header.checkbox`] v-slot:header="{ props }">-->
<!--        <v-checkbox-->
<!--            v-model="selectAll"-->
<!--            @change="selectAllItems"-->
<!--        ></v-checkbox>-->
<!--      </template>-->
<!--      <template v-slot:top>-->
<!--        تحديد الكل-->

<!--        <v-checkbox-->
<!--            v-model="selectAll"-->
<!--            @change="selectAllItems"-->
<!--        ></v-checkbox>-->
<!--      </template>-->

      <template v-slot:top>
        <div style="display: flex; align-items: center;">
          <span style="margin-right: 10px;">تحديد الكل</span>

          <v-checkbox
              v-model="selectAll"
              @change="selectAllItems()"
          ></v-checkbox>
        </div>
      </template>


      <template v-slot:item.checkbox="{ item }">
        <v-checkbox v-model="item.selected" @change="handleCheckboxChange(item)"></v-checkbox>
      </template>

      <template v-slot:item.created_at="{ item }">
        {{ new Date(item.created_at).toLocaleString("ar") }}
      </template>
      <template v-slot:item.expire_at="{ item }">
        {{ new Date(item.expire_at).toLocaleString("ar") }}
      </template>
      <template v-slot:item.details="{ item }">
        <v-btn

            small
            icon
            color="secondary"
            @click="handleDetails(item)"
        >
          تفاصيل
        </v-btn>
      </template>


      <!-- CONTROLS -->
      <template v-slot:item.controls="{ item }">
        <v-btn
            small
            icon
            color="secondary"
            class="mx-1"
            @click="
            SET_FORM_RECORD(item);
            SET_EDIT_MODE(true);
            SHOW_FORM();
          "
        >
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>

      </template>

      <!-- send-notification -->
      <template v-slot:item.send-notification="{ item }">
        <v-btn
            small
            icon
            color="secondary"
            class="mx-1"
            @click="SET_FORM_RECORD(item);SHOW_SEND_NOTIFICATION_FORM();"
        >
          <v-icon>mdi-send</v-icon>

        </v-btn>

      </template>


      <template v-slot:[`item.delete`]="{ item }">
        <v-dialog transition="dialog-top-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on"> حذف </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="red" dark>حذف الإعلان</v-toolbar>
              <v-card-text>
                <h4 class="my-3 red--text">هل تريد حذف هذا الإعلان</h4>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                    color="red"
                    dark
                    @click="deleteThisUserAd(item.id), (dialog.value = false)"
                >
                  حذف
                </v-btn>
                <v-btn text @click="dialog.value = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>

      <template v-slot:item.blocked="{ item }">
        <v-switch

            v-if="item.active == 0"
            inset
            :input-value="false"
            @change="unblock(item)"
        ></v-switch>
        <v-switch
            v-else="item.active == 1"
            color="success"
            inset
            :input-value="true"
            @change="block(item)"

        ></v-switch>
      </template>

      <template v-slot:item.block="{ item }">
        <v-spacer></v-spacer>
        <v-btn v-if="item.active" text @click="blockAddWithUserMsgs(item.id)">
          <v-icon color="red" class="mx-5"

          >mdi-account-tie-voice-off-outline

          </v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <!-- PAGINATION -->
    <div class="text-center mt-7">
      <v-pagination

          total-visible="7"
          @input="$router.replace({ query: { ...$route.query, page: $event } })"
          :value="parseInt(filters.page)"
          :length="totalPages"
          circle
      ></v-pagination>

      <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">

        <v-card>
          <v-card-title class="text-h5 black--text grey lighten-2">
            تفاصيل الاعلان
          </v-card-title>


          <v-container>
            <v-card-text>

            <v-simple-table>
              <template v-slot:default>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>السعر</td>
                    <td v-if="getDetails.price">{{ getDetails.price }}</td>
                    <td v-else>--</td>
                  </tr>
                  <tr>
                    <h3>معلومات العميل</h3>
                  </tr>
                  <tr v-if="getDetails.user">
                    <td>البلد</td>
                    <td>{{ getDetails.user.country }}</td>


                  </tr>


                  <!-- city -->
                  <tr>
                    <td>المحافظة</td>
                    <td v-if="getDetails.city">{{ getDetails.city.name }}</td>
                    <td v-else>--</td>
                  </tr>

                  <!-- area -->
                  <tr>
                    <td>المنطقة</td>
                    <td v-if="getDetails.area">{{ getDetails.area.name }}</td>
                    <td v-else>--</td>
                  </tr>

                  <tr>
                    <td>العنوان</td>
                    <td>{{ getDetails.address }}</td>
                  </tr>
                  <tr>
                    <td>الهاتف</td>
                    <td v-if="getDetails.user">
                      {{ getDetails.user.phone }}
                    </td>
                    <td v-else>--</td>
                  </tr>
<!--                  <tr>-->
<!--                    <td>البريد الالكتروني</td>-->
<!--                    <td v-if="getDetails.user">-->
<!--                      {{ getDetails.user.email }}-->
<!--                    </td>-->
<!--                    <td v-else>&#45;&#45;</td>-->
<!--                  </tr>-->

                  <tr>
                    <td>تاريخ النشأة</td>
                    <td>{{ getDetails.created_at }}</td>
                  </tr>
                  <tr>
                    <td>تاريخ الانتهاء</td>
                    <td>{{ getDetails.expire_at }}</td>
                  </tr>

                  <tr>
                    <td>اسم الاعلان</td>
                    <td>{{ getDetails.title }}</td>
                  </tr>
                  <tr>

                    <td>تفصيل الاعلان</td>
                    <td>{{ getDetails.description }}</td>
                  </tr>
                  <tr>

                    <td>المشاهدة</td>
                    <td>{{ getDetails.views }}</td>
                  </tr>
                  <tr v-if="getDetails.sub_category">
                    <td class="d-flex">
                      <span class="center">التصنيف الفرعي</span>
                    </td>
                    <td>{{ getDetails.sub_category.name }}</td>
                  </tr>
                  <tr v-if="getDetails.sub_category">
                    <td class="d-flex">
                      <span class="center">القسم</span>
                    </td>
                    <td>{{ getDetails.sub_category.category.name }}</td>
                  </tr>


                </tbody>
              </template>
            </v-simple-table>

              <!-- Map section -->
              <div id="map" style="height: 300px; margin: 30px;"></div>

            <!-- Centering Images -->
              <v-row class="text-center">
                <v-col v-for="(image, index) in dialogData.filter(item => item.file_type === 'image')" :key="index" cols="12" md="4">
                  <a :href="image.file" target="_blank" rel="noopener noreferrer">
                    <v-responsive>
                      <v-img
                          :src="image.file"
                          :aspect-ratio="16 / 9"
                          width="100%"
                          contain
                      ></v-img>
                    </v-responsive>
                  </a>
                </v-col>
              </v-row>


              <!-- Centering Videos -->
              <v-row class="text-center">
                <v-col v-for="(video, index) in dialogData.filter(item => item.file_type === 'video')" :key="index" cols="12" md="12">
                  <v-responsive>
                    <div class="video-container">
                      <video
                          style="max-width: 100%;"
                          class="mt-3 mb-2 mx-3 rounded-lg"
                          controls
                          preload="none"

                      >
                        <source :src="video.file" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>

                    </div>
                  </v-responsive>
                </v-col>
              </v-row>


          </v-card-text>
          </v-container>



          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false"> إغلاق </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import RecordForm from "./RecordForm";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import FiltersBox from "./FiltersBox.vue";

import SendNotificationForm from "@/views/userAds/SendNotificationForm.vue";
export default {
  name: "userAds",
  components: {SendNotificationForm, RecordForm, FiltersBox },


  data: function () {
    return {
      dialog: false,
      dialogData: [],
      selectedItem: {},
      width: 300,
      dialogDelete: false,
      selectAll: false,

      // table
      table: {
        headers: [
          {
            text: "حذف",
            value: "checkbox",
          },

          {
            value: "title",
            text: "العنوان",
          },
          {
            value: "country",
            text: "الدولة",
          },
          {
            value: "created_at",
            text: "تاريخ النشأة",
          },
          {
            value: "expire_at",
            text: "تاريخ الانتهاء ",
          },
          {
            value: "views",
            text: "المشاهدة",
          },

          //user_phone
          {
            value: "phone",
            text: "الهاتف",
          },
          {
            value: "blocked",
            text: "تعطيل/تفعيل (الاعلان)",
          },
          {
            value: "block",
            text: "حظر الاعلان و المستخدم",
          },
          {
            value: "details",
            text: "التفاصيل",
          },

          {
            value: "send-notification",
            text: "ارسال اشعار",
          },
          {
            text: "حذف",
            value: "delete",
          },
        ],
        loading: false,
      },
    };
  },

  computed: {

    ...mapState("userAds", [
      "records",
      "page",
      "totalPages",
      "filters",
      "total_ads",
    ]),
    ...mapGetters("userAds", ["getDetails"]),
  },
  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }
        this.SET_FILTERS({ ...route.query });
        this.fetchRecords();
      },
      deep: true,
      immediate: true,
    },


    getDetails: {
      handler() {
        // Update the map when getDetails changes
        this.updateMap();
      },
      deep: true,
      immediate: true,
    },

  },

  methods: {
    handleDetails(item) {
      // console.log(item)
      this.userAdsDetails(item.id);
      this.showDetails(item);
      this.selectedItem = item;


    },
    showDetails(item) {
      this.dialogData.length = 0;
      for (let image of item.ad_files) {
        this.dialogData.push(image);
      }
      this.dialog = true;
    },
    blockAddWithUserMsgs(id) {
      this.blockAddWithUser(id)
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: `تم حظر الاعلان و المستخدم`,
            color: "success",
          });
          this.$store.dispatch("userAds/fetchRecords");
        })
        .catch(() => {
          this.$bus.$emit("showSnackbar", {
            text: `حدث خطأ ما`,
            color: "error",
          });
        });
    },
    ...mapActions("userAds", ["blockAddWithUser", "userAdsDetails","deleteUserAd","multiDeleteUserAd"]),

    ...mapMutations("userAds", [
      "SHOW_FORM",
      "SET_EDIT_MODE",
      "SET_FORM_RECORD",
      "SHOW_RECORD_FORM",
      "SET_FILTERS",

      "SHOW_SEND_NOTIFICATION_FORM",

    ]),

    fetchRecords() {
      this.table.loading = true;
      this.$store
        .dispatch("userAds/fetchRecords")
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "Error while getting Data",
            color: "accent",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    async unblock(selectedRecord) {
      this.table.loading = true;

      await this.$store
        .dispatch("userAds/unblock", { record: { ...selectedRecord } })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: `تم فك حظر الاعلانات`,
            color: "success",
          });
          this.fetchRecords();
        })
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "Error while unblocking Admin  ",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    async block(selectedRecord) {
      this.table.loading = true;

      await this.$store
        .dispatch("userAds/block", { record: { ...selectedRecord } })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: `تم حظر الاعلانات`,
            color: "warning",
          });
          this.fetchRecords();
        })
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: " Error while blocking Admin",
            color: "red darken-3",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    updateMap() {
      // Check if Google Maps API is loaded
      if (typeof google === 'undefined') {
        return;
      }

      // Get lat and lng from getDetails
      const lat = parseFloat(this.getDetails.lat);
      const lng = parseFloat(this.getDetails.lng);

      // Create a map centered at the specified lat and lng
      const map = new google.maps.Map(document.getElementById('map'), {
        center: { lat, lng },
        zoom: 15, // Adjust the zoom level as needed
      });

      // Add a marker for the location
      new google.maps.Marker({
        position: { lat, lng },
        map,
        title: 'Location',
      });
    },
    deleteThisUserAd(id) {
      this.deleteUserAd(id).then(() => {
        this.fetchRecords();
      });
    },


    deleteSelectedAds() {
      const selectedAds = this.records.filter((item) => item.selected);
      const adIds = selectedAds.map((item) => item.id);
      if (adIds.length > 0) {
        this.multiDeleteUserAd(adIds).then(() => {
          this.fetchRecords();
        });
      } else {
        // Show a message indicating no ads are selected
        // You can use a snackbar or any other UI component for this
        console.log('No ads selected for deletion.');
      }
    },

    selectAllItems() {
      if (this.selectAll) {
        // Select all items
        this.records.forEach(item => item.selected = true);
      } else {
        // Deselect all items
        this.records.forEach(item => item.selected = false);
      }
    },


    handleCheckboxChange(item) {
      // Implement your logic here
      console.log('Checkbox changed for item:', item);
    }

  },
};
</script>
<style scoped>
.center {
  margin: auto 0 !important;
  padding: 5px;
}


.text-center {
  text-align: center;
}

/* Add responsive styles as needed */
@media only screen and (max-width: 600px) {
  /* Example: Adjust styles for smaller screens */
  .responsive-example {
    /* Add your responsive styles here */
  }
}

/* Arabic text styling adjustments */
html[dir="rtl"] body {
  font-family: "Your Arabic Font", sans-serif;
  /* Add other styling adjustments for Arabic text as needed */
}
</style>

