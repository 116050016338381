<template>
  <v-dialog
    :value="showForm"
    @input="(v) => v || HIDE_FORM()"
    max-width="600px"
    scrollable
  >
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{
          editMode ? " تعديل الاعلان" : " اضافة اعلان جديد"
        }}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>
            <v-autocomplete
              dense
              outlined
              hide-details
              clearable
              label="القسم"
              prepend-icon="mdi-sitemap"
              class="mb-5"
              item-text="section"
              item-value="id"
              v-model="form.section_id"
              :error-messages="errors.section_id"
              :items="sections"
            ></v-autocomplete>

            <v-autocomplete
              dense
              outlined
              hide-details
              clearable
              label="البلد"
              prepend-icon="mdi-sitemap"
              class="mb-5"
              item-text="country"
              item-value="id"
              v-model="form.country_id"
              :error-messages="errors.country_id"
              :items="countries"
            ></v-autocomplete>
            <label>من تاريخ</label>
            <input 
            type="datetime-local" 
            v-model="form.start_date" 
            :error-messages="errors.start_date"  required/>
            <br />
            <label>الى تاريخ</label>
            <input 
            type="datetime-local" 
            v-model="form.end_date" 
            :error-messages="errors.end_date" required/>
            <!-- <v-datetime-picker 
             dense
            outlined
            label="من تاريخ" 
            v-model="form.start_date"
            :error-messages="errors.start_date"
            > </v-datetime-picker>

            <v-datetime-picker 
             dense
            outlined
            label="الى تاريخ" 
            v-model="form.end_date"
            :error-messages="errors.end_date"
            > </v-datetime-picker> -->
            
            <v-file-input
              dense
              outlined
              label="الصورة"
              prepend-icon="mdi-image"
              accept="image/*"
              v-model="form.image"
              :error-messages="errors.image"
            ></v-file-input>
            <p class="error_class">*accept PNG SIZES 1200*1600</p>

          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="formLoading"
          color="secondary"
          elevation="1"
          dark
          @click="editMode ? update(form) : create(form)"
          >حفظ</v-btn
        >
        <v-btn
          @click="HIDE_FORM()"
          >الغاء</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapMutations , mapActions} from "vuex";

export default {
  name: "record-form",

  data: function() {
    return {
      form: {},
      errors: {},
      formLoading: false,
      sections: [],
      countries: [],
    };
  },
  created(){
      this.fetchCountries();
      this.fetchSections();
  },
  computed: {
    ...mapState("ads", {
      editMode: state => state.editMode,
      showForm: state => state.showForm,
      formRecord: state => {
        return {
          id: state.formRecord.id,
          start_date: state.formRecord.start_date,
          end_date: state.formRecord.end_date,
          country_id: state.formRecord.country_id,
          section_id: state.formRecord.section_id,
          // icon: state.formRecord.icon
        };
      }
    })
  },

  watch: {
    formRecord(form) {
      this.form = form;
    }
  },

  methods: {
    ...mapMutations("ads", ["HIDE_FORM"]),
    ...mapActions("ads", ["fetchRecords"]),
    fetchSections(){
      this.axios.get("/admin/sliders/section_dropdown").then((response) =>{
        this.sections = response.data;
      });
    },

    fetchCountries()
    {
      this.axios.get("/admin/sliders/countries_dropdown").then((response) => {
        this.countries = response.data;
      });
    },
    create(form) {
      this.formLoading = true;
      // form.section_id = this.item.id;
      // // console.log(form.section_id);
      // console.log(form);
      this.$store
        .dispatch("ads/create", { form })
        .then(response => {
          this.$bus.$emit("showSnackbar", {
            text: "تمت اضافة التصنيف بنجاح",
            color: "success"
          });
          this.HIDE_FORM();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit("showSnackbar", {
            text: "خطا اثناء اضافة التصنيف",
            color: "error"
          });
          this.HIDE_FORM();
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords();
        });
    },

    update(form) {
      this.formLoading = true;
      // console.log({ form });
      this.$store
        .dispatch("ads/update", { form })
        .then(() => {
          this.$bus.$emit("showSnackbar", {
            text: "Data updated",
            color: "success"
          });
          this.HIDE_FORM();
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          this.$bus.$emit("showSnackbar", {
            text: "There was a mistake when updating the category.",
            color: "error"
          });
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords();
        });
    }
  }
};
</script>
